import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {
  Dialog,
  Input,
  InputNumber,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Select,
  Option,
  Button,
  Form,
  FormItem,
  Tabs,
  TabPane,
  Icon,
  Row,
  Col,
  Card,
  Carousel,
  CarouselItem,
  Container,
  Header,
  Main,
  Footer,
  Loading,
  Message,
  Drawer
} from 'element-ui'
import './customTheme/index.css'
import './style/index.css'

Vue.config.productionTip = false
Vue.use(Button)
Vue.use(Select)
Vue.use(Dialog)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Checkbox)
Vue.use(CheckboxButton)
Vue.use(CheckboxGroup)
Vue.use(Select)
Vue.use(Option)
Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Tabs)
Vue.use(TabPane)

Vue.use(Icon)
Vue.use(Row)
Vue.use(Col)
Vue.use(Card)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Container)
Vue.use(Header)
Vue.use(Main)
Vue.use(Footer)
Vue.use(Drawer)

Vue.prototype.$loading = Loading.service
Vue.prototype.$message = Message

new Vue({
  router,
  store,
  mounted () {
    document.dispatchEvent(new Event('app-element'))
  },
  render: h => h(App)
}).$mount('#app')
