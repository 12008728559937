<template>
    <div class="header-container">
        <div class="header-bar">
            <div v-if='windowSize !== "big"' ref="menu" id="nav-icon2" @click="toggleMenu">
                <span></span>
                <span></span>
                <span></span>
            </div>

            <div class="logo" @click="setActiveTag(0, 'home')">
                <div class="company-616"></div>
                <!-- <img src="@/assets/logo/616companyLogo.png" alt="" width="120" heigth="50"
                        @click="setActiveTag(0, 'home')"> -->
            </div>
            <ul v-if='windowSize === "big"' class="header-bar-ul">
                <li @click="setActiveTag(1, 'home')" :class="activeTab === 1 ? 'active-tag' : ''">
                    {{ internationalDict.homePage }}
                </li>
                <li @click="setActiveTag(2, 'aboutUs')" :class="activeTab === 2 ? 'active-tag' : ''">
                    {{ internationalDict.aboutUs }}
                </li>
                <li @click="setActiveTag(3, 'hotSale')" :class="activeTab === 3 ? 'active-tag' : ''">
                    {{ internationalDict.lastPromotion }}
                </li>
                <li @click="setActiveTag(7, 'takeAway')" :class="activeTab === 7 ? 'active-tag' : ''">
                        {{ internationalDict.takeAway }}
                </li>
                <li @click="setActiveTag(4, 'address')" :class="activeTab === 4 ? 'active-tag' : ''">
                    {{ internationalDict.address }}
                </li>
                <li @click="setActiveTag(5, 'contactUs')" :class="activeTab === 5 ? 'active-tag' : ''">
                    {{ internationalDict.contactUs }}
                </li>
                <li @click="setActiveTag(6, 'joinUs')" :class="activeTab === 6 ? 'active-tag' : ''">
                    {{ internationalDict.joinUs }}
                </li>
            </ul>

            <div class="international">
                <svg class="pointer" @click="setLangure()" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M3 5H15M9 3V5M10.0482 14.5C8.52083 12.9178 7.28073 11.0565 6.41187 9M12.5 18H19.5M11 21L16 11L21 21M12.7511 5C11.7831 10.7702 8.06969 15.6095 3 18.129"
                        stroke="#206b6e" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </div>
        </div>

        <el-drawer title="" :visible.sync="drawer" direction="ltr" :modal-append-to-body="false" size='50%'
            :before-close="toggleMenu">
            <ul class="header-bar-ul-mobile">
                <li @click="setActiveTag(1, 'home')" :class="activeTab === 1 ? 'active-tag' : ''">
                    {{ internationalDict.homePage }}
                </li>
                <li @click="setActiveTag(2, 'aboutUs')" :class="activeTab === 2 ? 'active-tag' : ''">
                    {{ internationalDict.aboutUs }}
                </li>
                <li @click="setActiveTag(3, 'hotSale')" :class="activeTab === 3 ? 'active-tag' : ''">
                    {{ internationalDict.lastPromotion }}
                </li>
                <li @click="setActiveTag(7, 'takeAway')" :class="activeTab === 7 ? 'active-tag' : ''">
                        {{ internationalDict.takeAway }}
                </li>
                <li @click="setActiveTag(4, 'address')" :class="activeTab === 4 ? 'active-tag' : ''">
                    {{ internationalDict.address }}
                </li>
                <li @click="setActiveTag(5, 'contactUs')" :class="activeTab === 5 ? 'active-tag' : ''">
                    {{ internationalDict.contactUs }}
                </li>
                <li @click="setActiveTag(6, 'joinUs')" :class="activeTab === 6 ? 'active-tag' : ''">
                    {{ internationalDict.joinUs }}
                </li>
            </ul>
        </el-drawer>
    </div>
</template>
<script>
export default {
    name: "header-bar",
    components: {},
    mixins: [],
    props: {},
    data() {
        return {
            activeTab: -1,
            lang: 1,
            drawer: false,
        };
    },
    computed: {
        internationalDict() {
            return this.$store.getters.getInternationalDict;
        },
        internationalstate() {
            return this.$store.getters.getInternationalstate;
        },
        windowSize() {
            return this.$store.getters.getWindowSize;
        }
    },
    watch: {},
    mounted() { },
    methods: {
        setActiveTag(index, name) {
            this.activeTab = index;
            if (name) {
                this.$router.push({ name })
                .then(() => {
                    this.drawer = false;
                })
                .catch(() => { });
            }

        },
        setLangure() {
            let flag = this.internationalstate === "chinese" ? "english" : "chinese";
            this.$store.dispatch("commitInternationalStatus", flag);
        },
        toggleMenu() {
            this.drawer = !this.drawer;
        },
    },
};
</script>
<style scoped>
/* @media screen and (min-width: 1000px) {
    .company-616 {
        --logo-width: 20vw;
    }
}

@media screen and (width < 1000px) and (width > 600px) {
    .company-616 {
        --logo-width: 30vw;
    }
}

@media screen and (max-width: 600px) {
    .company-616 {
        --logo-width: 55vw;
    }
} */

.header-bar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 25px;
}

.logo {
    flex: 1;
}

.company-616 {
    background-image: url("../assets/img/logo/616companyLogo.jpg");
    height: 50px;
    background-size: auto 25px;
    background-repeat: no-repeat;
    background-position: 0 50%;
    cursor: pointer;
}

.international {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-left: 3em;
}

.header-bar-ul {
    display: flex;
    list-style: none;
    align-items: center;
    gap: 2em;
}

.header-bar-ul-mobile {
    display: flex;
    flex-direction: column;
    list-style: none;
    gap: 1em;
    text-align: center;
}

.header-bar-ul>li,
.header-bar-ul-mobile>li {
    font-weight: 600;
    position: relative;
    cursor: pointer;
    white-space: normal;
    color: var(--text-color-gary);
    font-size: var(--title-font-size);
}

.header-bar-ul .active-tag {
    color: var(--primary-color) !important;
    border-bottom: 2px solid var(--primary-color) !important;
}

.header-bar-ul-mobile .active-tag {
    color: var(--primary-color) !important;
}
</style>
