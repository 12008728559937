<template>
    <div>
        <h2 class="sub-title">{{ internationalDict.ourBrand }}</h2>
        <div class="brand-table">
            <!-- <div :class="windowSize === 'big' ? 'brand-table' : 'brand-table-mobile'"></div> -->
                <el-card v-for="(item, index) of brandList" :key="index" :body-style="{padding:'15px 10px'}" style="borderRadius:10px">
                    <div class='brand-table-item'>
                        <div class="flex1 flex-row-center">
                            <img :src="item.img" alt="" class="brand-item-logo">
                        </div>
                        <span class="brand-text">{{ internationalstate === 'english' ? item.nameEng : item.name }}</span>
                    </div>
                </el-card>
        </div>
    </div>
</template>
<script>
export default {
    name: 'our-bind',
    components: {

    },
    mixins: [],
    props: {

    },
    data() {
        return {
            brandList: [
                {
                    name: '616燒肉酒場',
                    nameEng: '616 YanKiniku',
                    img: require('@/assets/img/logo/bbqLogo.png'),
                    width: 162,
                    height: 150,
                },
                {
                    name: '虎喰燒肉酒場',
                    nameEng: 'Tora Kui Japanese Yakiniku',
                    img: require('@/assets/img/logo/tiger_logo_v1.png'),
                    width: 130,
                    height: 150,
                },
                {
                    name: '616牛肉火鍋專門店',
                    nameEng: '616 Hotpot',
                    img: require('@/assets/img/logo/616hotpot.png'),
                    width: 200,
                    height: 80,
                },
                // {
                //     name: '千絵日本料理',
                //     nameEng: 'Chie Japanese Cuisine',
                //     img: require('@/assets/logo/thousandLogo.png'),
                //     width: 150
                // },
                {
                    name: '616客香邨',
                    nameEng: '616 HakKaInn',
                    img: require('@/assets/img/logo/villageLogo.png'),
                    width: 80,
                    height: 150,
                }
            ]
        }
    },
    computed: {
        internationalDict() {
            return this.$store.getters.getInternationalDict
        },
        internationalstate() {
            return this.$store.getters.getInternationalstate
        },
        windowSize() {
            return this.$store.getters.getWindowSize;
        }
    },
    watch: {

    },
    mounted() {
        //  this.internationalDict = this.$store.getters.getInternationalDict
    },
    methods: {

    }
};
</script>
<style scoped>
.brand-table {
    --logo-size: 140px;
    display: grid;
    min-width:calc(175 * 2 + 2em) ;
    place-content: center;
    /* align-content: center; */
    grid-template-columns: repeat(auto-fit,175px);
    gap: 1em;
    
}

.brand-table-item {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.brand-item-logo {
    height: var(--logo-size);
    width: var(--logo-size);
    object-fit: contain;
}
.brand-text {
    padding-top: 5px;
    color: var(--primary-color);
    font-weight: 600;
    text-align: center;
}
</style>