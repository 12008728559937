import Vue from 'vue'
import VueRouter from 'vue-router'
import homeView from '../views/homeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: homeView
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: ()=> import('../views/aboutUsView.vue')
  },
  {
    path: '/hotSale',
    name: 'hotSale',
    component: ()=> import('../views/hotSaleView.vue')
  },
  {
    path: '/address',
    name: 'address',
    component: ()=> import('../views/addressView.vue')
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: ()=> import('../views/contactUsView.vue')
  },
  {
    path: '/takeAway',
    name: 'takeAway',
    component: ()=> import('../views/takeAwayView.vue')
  },
  {
    path: '/joinUs',
    name: 'joinUs',
    component: ()=> import('../views/joinUsView.vue')
  },
  {
    path: '/hotSaleView',
    name: 'hotSaleView',
    component: ()=> import('../views/hotSaleView.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: ()=> import('../views/privacyView.vue')
  },
  {
    path: '*',
    redirect:'/'
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.fullPath === '/#/takeAway') {
    next('/takeAway');
  } else {
    next();
  }
})

export default router
