<template>
  <div id="app">
    <!-- <router-view /> -->
    <MainView></MainView>
  </div>
</template>

<script>
import MainView from './views/mainView.vue'
export default {
  components: { MainView }
}
</script>

<style>

</style>
