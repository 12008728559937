<template>
  <div class="home">
    <el-carousel class="carousel">
      <el-carousel-item v-for="(item, index) of imgList" :key="index" :interval="6000">
          <img :src="item.img" :alt="item.name" class="carousel-img">
      </el-carousel-item>
    </el-carousel>

    <div class="container flex-column-center">
      <span class="slogan">{{ internationalDict.slogan }}</span>
    </div>
    <aboutUs :isAbstract="true"></aboutUs>
    <div class="container">
      <ourBrands></ourBrands>
    </div>
  </div>
</template>

<script>
import ourBrands from '@/components/ourBrands.vue';
import aboutUs from './aboutUsView.vue';
export default {
  name: 'HomeView',
  components: { ourBrands , aboutUs},
  data() {
    return {
      imgList: [
        {
          name: 'photo-output_3',
          img: require('@/assets/img/carousel/photo-output_3.jpg')
        },
        {
          name: 'photo-output_1',
          img: require('@/assets/img/carousel/photo-output_1.jpg')
        },
        {
          name: 'photo-output_4',
          img: require('@/assets/img/carousel/photo-output_4.jpg')
        },
        {
          name: 'photo-output_6',
          img: require('@/assets/img/carousel/photo-output_6.png')
        },
        {
          name: 'photo-output_7',
          img: require('@/assets/img/carousel/photo-output_7.jpg')
        },
      ]
    }
  },
  computed: {
    internationalDict() {
      return this.$store.getters.getInternationalDict
    }
  }
}
</script>
<style>
.home{
  padding-bottom: 3em;
}
.el-carousel__container {
  width: 100%;
  height: auto !important;
  aspect-ratio: 1920 / 831;
}

.carousel-img {
  object-fit: contain;
  object-position: 50% 50%;
  width: 100%;
  /* height: var(--carousel-hight); */
}

.slogan {
  padding-top: 2em;
  font-family: PingFangSC-Medium;
  font-size: var(--slogan-size);
  font-weight: 500;
  color: #333;
  letter-spacing: 6px;
  text-align: center;
}
</style>
