import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    windowSize:'',
    internationalStatus:'chinese',
    international:{
      chinese:{
        homePage:'首頁',
        aboutUs:'關於我們',
        aboutUsDetail:'關於616',
        ourBrand:'旗下品牌',
        award:'得獎',
        lastPromotion:'最新推廣',
        recentoffers:'近期優惠',
        hotSale:'熱賣推介',
        address:'分店地址',
        takeAway:'外賣落單',
        takeOut:'外 賣 自 取',
        contactUs:'聯繫我們',
        contactUsWay:'聯繫方法',
        contactUsDetail:'您可透過電話或電郵與我們聯絡',
        joinUsDetail:'您可透過電話或whatsapp與我們聯絡',
        contactUsForm:{
          onlineForm:'網上表格',
          typeOfFeedback:'您的反映事項',
          eventDateAndTime:'事發日期和時間',
          comments:'您的意見',
          personalInformation:'個人資料',
          name:'姓名',
          email:'聯絡電郵',
          phoneNumber:'聯絡電話'
        },
        joinUs:'加入我們',
        hotjob:'熱門職位',
        jobHotLine:'招聘專線',
        location:'分店搜索',
        addressList:['香港','九龍','新界'],
        slogan:'食盡世界各地各種刁鑽牛部位',
        telephone:'電話',
        email:'電郵地址',
        workingHour:'辦公時間',
        workingHourContext:'星期一至五上午9時至下午6時（星期六、日及公眾假期休息）'
      },
      english:{
        homePage:'Home',
        aboutUs:'About Us',
        aboutUsDetail:'About 616',
        ourBrand:'Our Brands',
        award:'Award',
        lastPromotion:'Last Promotion',
        recentoffers:'Recent Offers',
        hotSale:'Hot Sale',
        address:'Branch Address',
        takeAway:'Takeaway Order',
        takeOut:'Take Out',
        contactUs:'Contact Us',
        contactUsWay:'Contact Information',
        contactUsDetail:'You can contact us by phone or email',
        joinUsDetail:'You can contact us by phone or whatsapp',
        contactUsForm:{
          onlineForm:'Online form',
          typeOfFeedback:'Type of feedback',
          eventDateAndTime:'Event date and time',
          comments:'Comments',
          personalInformation:'Personal information',
          name:'name',
          email:'email',
          phoneNumber:'phoneNumber'
        },
        joinUs:'Join Us',
        hotjob:'Popular positions',
        jobHotLine:'Recruitment hotline',
        location:'location',
        addressList:['Hong Kong Island','Kowloon','New Territories'],
        slogan:'食盡世界各地各種刁鑽牛部位',
        telephone:'Tel',
        email:'Email',
        workingHour:'Working Hour',
        workingHourContext:'Monday to Friday 9am - 6pm (Close on Saturday, Sunday and Public Holiday)'
      }
    }
  },
  getters: {
    getInternationalDict(state){
      return state.international[state.internationalStatus]
    },
    getInternationalstate(state){
      return state.internationalStatus
    },
    getWindowSize(state){
      return state.windowSize
    }
  },
  mutations: {
    setInternationalStatus(state,lang){
      state.internationalStatus = lang
    },
    setWindowSize(state,size){
      state.windowSize = size
    }
  },
  actions: {
    commitInternationalStatus(store,flag){
      store.commit('setInternationalStatus', flag)
    },
    commitWindowSize(store,size){
      store.commit('setWindowSize', size)
    }
  },
  modules: {
  }
})
