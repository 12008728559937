<template>
    <div>
        <el-container>
            <el-header height="60px" class="header">
                <headerBar></headerBar>
            </el-header>
            <el-container>
                <el-container>
                    <el-main class="main">
                        <transition name="fade">
                            <router-view />
                        </transition>
                    </el-main>
                    <el-footer height="50px">
                        <footerBar></footerBar>
                    </el-footer>
                </el-container>
            </el-container>
        </el-container>
    </div>
</template>
<script>
import headerBar from '../components/headerBar.vue'
import footerBar from '../components/footerBar.vue'
export default {
    name: 'main-page',
    components: {
        headerBar,
        footerBar
    },
    mixins: [],
    props: {

    },
    data() {
        return {
            size: ''
        }
    },
    computed: {

    },
    watch: {

    },
    mounted() {
        this.windowResize()
        window.onresize = () => {
            this.windowResize()
        }
    },
    methods: {
        windowResize() {
            let size = window.innerWidth > 1200 ? "big" : window.innerWidth > 760 ? 'small' : 'mobile';
            if (this.size !== size) {
                this.$store.dispatch("commitWindowSize", size);
            }
            this.size = size
        }
    }
};
</script>
<style scoped>
.header {
    background-color: #fff;
    /* border-bottom: 1px solid var(--text-color-gary); */
    /* margin: 0 1em; */
    box-shadow: 0 0 5px 2px rgba(227, 227, 227, 0.8);
    position: sticky;
    top: 0;
    z-index: 999;
}

.main {
    padding-bottom: 0;
    overflow-x: hidden;
}

/* fade */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease-in;
}

.fade-enter-from {
    opacity: 0;
}

.fade-leave-to {
    opacity: 0;
}

/* leftin */
.leftin-leave-active {
    transition: all 0.2s ease-out;
}

.leftin-enter-from {
    opacity: 1;
    transform: translateX(0);
}

.leftin-leave-to {
    transform: translateX(80%);
    opacity: 0;
}
</style>