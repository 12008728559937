<template>
   <div class="about-us">

      <div v-if="isAbstract">
         <div class="container">
            <h2 class="sub-title">{{ internationalDict.aboutUsDetail }}</h2>
         </div>
         <div v-if="internationalstate === 'chinese'">
            <el-row justify="center" type="flex" :gutter="50" class="about-us-box">
               <el-col :xs="22" :sm="9" :md="8" :lg="8" :xl="9">
                  <img src="@/assets/img/aboutus/photo-output_1.jpg" alt="" class="about-us-img">
               </el-col>
               <el-col :xs="22" :sm="9" :md="9" :lg="8" :xl="9">
                  <h3>集團概況</h3>
                  <p>616餐飲管理有限公司於2016年成立，是一家由牛肉火鍋、日本餐廳和中式餐廳組成的餐飲公司，其中包括：616牛肉火鍋專門店﹑616燒肉酒場﹑616客香邨﹑虎喰燒肉・酒場。</p>
                  <p>我們的分店偏佈香港、九龍和新界，目標客戶為 Z 世代。</p>
               </el-col>
            </el-row>
         </div>
         <div v-if="internationalstate === 'english'">
            <el-row justify="center" type="flex" :gutter="50" class="about-us-box">
               <el-col :xs="22" :sm="9" :md="8" :lg="8" :xl="9">
                  <img src="@/assets/img/aboutus/photo-output_1.jpg" alt="" class="about-us-img">
               </el-col>
               <el-col :xs="22" :sm="9" :md="9" :lg="8" :xl="9">
                  <h3>Group overview</h3>
                  <p>Founded in 2016, 616 Catering Management Limited (a company incorporated in Hong Kong) is food and
                     beverage company comprised of Beef hotpot, Japanese restaurant and Chinese restaurant which are
                     including the 616 Hotpot (616牛肉火鍋專門店)﹑616 YanKiniku (616燒肉酒場)﹑616 HakKaInn (616客香邨)﹑Tora Kui
                     Japanese Yakiniku (虎喰燒肉酒場)</p>
                  <p>All of the outlets in Hong Kong, Kowloon & New Territories as target customers for Gen Z.</p>
               </el-col>
            </el-row>
         </div>
         <el-row class="more-btn" justify="center" type="flex">
            <el-col :span="4" :offset="18">
               <el-button type="text" @click="toAboutUsView">
                  <span class="more-btn-text">more >></span>
               </el-button>
            </el-col>
         </el-row>
      </div>

      <div v-if="!isAbstract">
         <!-- <h2 class="title">{{ internationalDict.aboutUsDetail }}</h2> -->

         <div class='aboutus-chinese-box' v-if="internationalstate === 'chinese'">
            <el-row justify="center" type="flex" :gutter="50" class="about-us-box">
               <el-col :xs="22" :sm="9" :md="8" :lg="8" :xl="9">
                  <img src="@/assets/img/aboutus/photo-output_1.jpg" alt="" class="about-us-img">
               </el-col>
               <el-col :xs="22" :sm="9" :md="9" :lg="8" :xl="9">
                  <h3>集團概況</h3>
                  <p>616餐飲管理有限公司於2016年成立，是一家由牛肉火鍋、日本餐廳和中式餐廳組成的餐飲公司，其中包括：616牛肉火鍋專門店﹑616燒肉酒場﹑616客香邨﹑虎喰燒肉酒場。</p>
                  <p>我們的分店偏佈香港、九龍和新界，目標客戶為 Z 世代。</p>
               </el-col>
            </el-row>
            <el-row justify="center" type="flex" :gutter="50" class="about-us-box">
               <el-col :xs="22" :sm="9" :md="9" :lg="8" :xl="9">
                  <img src="@/assets/img/aboutus/photo-output_3.jpg" alt="" class="about-us-img">
               </el-col>
               <el-col :xs="22" :sm="9" :md="9" :lg="8" :xl="9">
                  <h3>第一間616牛肉火鍋專門店</h3>
                  <p>首間616牛肉火鍋專門店成立於2016年，位於香港新界大埔翠怡街3號翠怡廣場1樓18號舖。</p>
                  <p>首創以刁鑽牛肉部位作招徠的火鍋店，提供高品質、專家級牛肉火鍋產品的餐廳用餐服務。</p>
               </el-col>
            </el-row>
            <el-row justify="center" type="flex" :gutter="50" class="about-us-box">
               <el-col :xs="22" :sm="9" :md="9" :lg="8" :xl="9">
                  <img src="@/assets/img/aboutus/photo-output_8.jpg" alt="" class="about-us-img">
               </el-col>
               <el-col :xs="22" :sm="9" :md="9" :lg="8" :xl="9">
                  <h3>中央食品工場及辦公室總部</h3>
                  <p>616集團是香港極少數擁有自己的中央食品工場及辦公室總部的火鍋店之一。</p>
                  <p>於2021年12月，我們的中央食品工場及辦公室總部已擴建並搬遷至荃灣立泰工業中心，其總面積為 22,000 平方尺，使我們能精心將食品加工並由自家物流車隊每天把新鮮食品直送至本集團旗下 20
                     多家餐廳。
                  </p>
                  <p>我們的中央食品工場於 2022 年 4 月獲得了 ISO 22000 和 HACCP 證書。品質均有統一保證，提供優質及安全食品予顧客。</p>
               </el-col>
            </el-row>
            <el-row justify="center" type="flex" :gutter="50" class="about-us-box">
               <el-col :xs="22" :sm="9" :md="9" :lg="8" :xl="9">
                  <img src="@/assets/img/aboutus/photo-output_4.jpg" alt="" class="about-us-img">
               </el-col>
               <el-col :xs="22" :sm="9" :md="9" :lg="8" :xl="9">
                  <h3>616牛肉火鍋專門店x虎喰燒肉酒場旗艦店</h3>
                  <p>在2021 年 12 月 20 日，616牛肉火鍋專門店x虎喰燒肉酒場旗艦店於將軍澳PopDeli 開業，總面積為 4,542 平方尺。</p>
                  <p>而 PopDeli 位於將軍澳地鐵站上蓋的將軍澳中心，座落於將軍澳真正中心點。</p>
               </el-col>
            </el-row>
         </div>
         <div class='aboutus-english-box' v-if="internationalstate === 'english'">
            <el-row justify="center" type="flex" :gutter="50" class="about-us-box">
               <el-col :xs="22" :sm="9" :md="9" :lg="8" :xl="9">
                  <img src="@/assets/img/aboutus/photo-output_1.jpg" alt="" class="about-us-img">
               </el-col>
               <el-col :xs="22" :sm="9" :md="9" :lg="8" :xl="9">
                  <h3>Group overview</h3>
                  <p>Founded in 2016, 616 Catering Management Limited (a company incorporated in Hong Kong) is food and beverage company comprised of Beef hotpot, Japanese restaurant and Chinese restaurant which are including the 616 Hotpot (616牛肉火鍋專門店)﹑616 YanKiniku (616燒肉酒場)﹑616 HakKaInn (616客香邨) and Tora Kui Japanese Yakiniku (虎喰燒肉・酒場). </p>
                  <p>All of the outlets in Hong Kong, Kowloon & New Territories as target customers for Gen Z.</p>
               </el-col>
            </el-row>
            <el-row justify="center" type="flex" :gutter="50" class="about-us-box">
               <el-col :xs="22" :sm="9" :md="9" :lg="8" :xl="9">
                  <img src="@/assets/img/aboutus/photo-output_3.jpg" alt="" class="about-us-img">
               </el-col>
               <el-col :xs="22" :sm="9" :md="9" :lg="8" :xl="9">
                  <h3>The First 616 Hotpot Restaurant</h3>
                  <p>The first 616 Hotpot Restaurant (616牛肉火鍋專門店) was established in 2016 as located at Shop 18
                     Level
                     1, Greenery Plaza 3 Chui Yi Street, Tai Po, New Territories, Hong Kong.</p>
                  <p>It is providing high quality and an expert’s beef hotpot product of restaurant dinning services.
                  </p>
               </el-col>
            </el-row>
            <!-- <el-row justify="center" type="flex" :gutter="50" class="about-us-box">
            <el-col :span="9">
               <h3>The Flagship Restaurant of 616 Hotpot x Tora Kui Japanese Yakiniku</h3>
               <p>Besides, the flagship restaurant of 616 Hotpot (616牛肉火鍋專門店) x Tora Kui Japanese Yakiniku (虎喰燒肉酒場) as the total net floor area 4,542sq.ft at PopDeli was launched on 20 Dec 2021.  And the shop location PopDeli is centrally located at the hub of Tseung Kwan O atop Tseung Kwan O MTR Station.</p>
            </el-col>
            <el-col :span="9">
               <img src="" alt="" class="about-us-img">
            </el-col>
         </el-row> -->
            <el-row justify="center" type="flex" :gutter="50" class="about-us-box">
               <el-col :xs="22" :sm="9" :md="9" :lg="8" :xl="9">
                  <img src="@/assets/img/aboutus/photo-output_8.jpg" alt="" class="about-us-img">
               </el-col>
               <el-col :xs="22" :sm="9" :md="9" :lg="8" :xl="9">
                  <h3>Food & Beverage Production Centre and Head Office</h3>
                  <p>616 Group is amongst the very few Hot Pot restaurants that operate its own Food & Beverage
                     Production
                     Centre and Head Office. </p>
                  <p>In Dec of 2021, our Food & Beverage Production Centre & Head Office have been expanded and
                     relocated
                     to Leader Industrial Centre in Tsuen Wan. Its gross area is 22,000 sq.ft. and enables us to process
                     food more carefully and deliver fresh food directly to more than 20 restaurants under the group by
                     our
                     own logistics team every day.</p>
                  <p>Our Food & Beverage Production Centre gained ISO 22000 and HACCP certificates in April 2022.
                     Quality
                     is uniformly guaranteed, providing high-quality and safe food to customers.</p>
               </el-col>
            </el-row>
            <el-row justify="center" type="flex" :gutter="50" class="about-us-box">
               <el-col :xs="22" :sm="9" :md="9" :lg="8" :xl="9">
                  <img src="@/assets/img/aboutus/photo-output_4.jpg" alt="" class="about-us-img">
               </el-col>
               <el-col :xs="22" :sm="9" :md="9" :lg="8" :xl="9">
                  <h3>The Flagship Restaurant of 616 Hotpot x Tora Kui Japanese Yakiniku</h3>
                  <p>Besides, the flagship restaurant of 616 Hotpot (616牛肉火鍋專門店) x Tora Kui Japanese Yakiniku
                     (虎喰燒肉酒場)
                     as the total net floor area 4,542sq.ft at PopDeli was launched on 20 Dec 2021.</p>
                  <p>And the shop location PopDeli is centrally located at the hub of Tseung Kwan O atop Tseung Kwan O
                     MTR
                     Station.</p>
               </el-col>
            </el-row>
         </div>
      </div>

      <div v-if="!isAbstract" class="container">
         <!-- <h2 class="sub-title">{{ internationalDict.award }}</h2> -->
         <!-- <el-timeline>
            <el-timeline-item timestamp="2022" placement="top" color="#206b6e">
               <el-card>
                  <h4>《新假期》</h4>
                  <p>2022必吃食店大獎必吃火鍋✨大獎</p>
               </el-card>
            </el-timeline-item>
         </el-timeline> -->

         <ourBrands></ourBrands>
      </div>
   </div>
</template>
<script>
import ourBrands from '@/components/ourBrands.vue';
export default {
   name: 'about-us',
   components: {
      ourBrands
   },
   mixins: [],
   props: {
      isAbstract: {
         type: Boolean,
         require: true,
         default: false
      }
   },
   data() {
      return {

      }
   },
   computed: {
      internationalDict() {
         return this.$store.getters.getInternationalDict
      },
      internationalstate() {
         return this.$store.getters.getInternationalstate
      },
   },
   watch: {

   },
   mounted() {

   },
   methods: {
      toAboutUsView() {
         this.$router.push({ name: 'aboutUs' })
      }
   }
};
</script>
<style scoped>
.about-us {
   overflow: hidden;
   padding-bottom: 3em;
}
.about-us h3{
   color: var(--primary-color);
}
.about-us-img{
   width: 100%;
   aspect-ratio: 16 / 9;
   object-fit: cover;

}

.about-us p {
   text-align: left;
   line-height: 1.5rem;
   margin-bottom: 1em;
   font-weight: 500;
}

.about-us h3 {
   text-align: left;
   line-height: 1.5rem;
   margin-bottom: 1em;
   font-weight: 700;
}

.about-us-box {
   flex-wrap: wrap;
   padding: 2em 0;
}

.more-btn {
   display: flex;
   justify-content: flex-end;
}

.more-btn-text {
   padding-right: 15px;
   font-size: 16px;
   font-weight: 600;
}


@media screen and (min-width: 700px) {

   .aboutus-chinese-box>.about-us-box:nth-child(2n),
   .aboutus-english-box>.about-us-box:nth-child(2n) {
      flex-direction: row-reverse;
   }
}

@media screen and (max-width: 700px) {
   .about-us {
      flex-direction: row;
   }
}
</style>
