<template>
   <div>
      <div class="footer">
         <div>
            <div class="visitable-switch">616餐飲管理有限公司</div>
         </div>
         <div>
            <span @click="toPrivacyPage" class="privacy">Terms and Conditions</span>
            <span class="visitable-switch">Copyright © 2022 616Catering Management Ltd. All Rights Reserved.</span>
         </div>
      </div>

   </div>
</template>
<script>
export default {
   name: 'footer-bar',
   components: {

   },
   mixins: [],
   props: {

   },
   data() {
      return {

      }
   },
   computed: {

   },
   watch: {

   },
   mounted() {

   },
   methods: {
      toPrivacyPage() {
         this.$router.push({ name: 'privacy' })
      }
   }
};
</script>
<style scoped>
.privacy {
   padding-right: 1em;
   cursor: pointer;
}

.footer {
   padding: 0 25px;
   height: 50px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   font-size: 12px;
   background-color: var(--primary-color);
   color: var(--text-color-white);
}

.btn-group {
   display: flex;
   padding-bottom: 0.5em;
   padding-left: 1em;
   gap: 1em;
}

.IG,
.FB {
   height: 25px;
   width: 25px;
   background-size: cover;
   cursor: pointer;
   border-radius: 20%;
   box-shadow: 2px 2px 1px #000;
}

.FB {
   background-image: url('../assets/img/logo/FB.png');
}

.IG {
   background-image: url('../assets/img/logo/IG.png');
}
</style>